import React, { FC } from 'react';
import { FormQuestionsI } from 'intarfaces/form/createForm';
import { FormAnswers } from 'ITcomponents/formAnswers/FormAnswers';
import styles from './FormQuestions.module.scss';

export const FormQuestions: FC<FormQuestionsI> = ({ question, index }) => {
  return (
    <div className={styles.form__question}>
      <p className={styles.form__question__title}>
        {`
                    ${index}. 
                    ${question.question} 
                `}{' '}{
        question.required &&
        <span className={styles.required__question}>*</span>
      }
      </p>
      <div className={styles.form__answers}>
        <FormAnswers question={question} />
      </div>
    </div>
  );
};