import { useNavigate } from 'react-router-dom';
import { Button } from '../buttons/blueButton/Button';
import { ImgComponent } from '../imgClomponent/Img';
import Image1 from '../../assets/img/image1.jpg';
import styles from './mainComponents.module.scss';

export const WelcomeComponent = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('access');

  return (
    <div className={styles.about}>
      <div className={styles.about__wrapper}>
        <div className={styles.about__text}>
          <h1 className={styles.about__title}>Добро пожаловать в "Alabuga Forms". Начните использовать наши
            возможности прямо сейчас!</h1>
          <p className={styles.about__pre__title}>С легкостью создавайте формы и опросы, а также анализируйте
            их результаты. </p>
          <Button onClick={token ? () => navigate('/all_forms') : () => navigate('/auth')}
                  className="blue__button">Перейти в формы</Button>
        </div>
        <ImgComponent className={styles.about__img} id="targetElementHi" src={Image1} alt="image" />
      </div>
    </div>
  );
};
