import { FormVariableAnswer } from 'ITcomponents/formVariableAnswer/FormVariableAnswer';
import styles from './FormAnswers.module.scss';
import { FormQuestionsI } from 'intarfaces/form/createForm';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { addAnswer } from 'slices/forms/FormSlice';

export const FormAnswers: FC<FormQuestionsI> = ({ question }) => {
  const dispatch = useDispatch();
  return <>
    {question.choices.length ? (
      question.choices.map((choice, index) => (
        <FormVariableAnswer key={index} question={question} choice={choice} />
      ))
    ) : (
      <input
        type="text"
        required={question.required}
        className={styles.answer__input__basic}
        placeholder="Мой ответ"
        onChange={(event) =>
          dispatch(addAnswer({ question, answerValue: event.target.value }))
        }
      />
    )}
  </>;
};