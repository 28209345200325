import { useEffect } from 'react';
import { RootState } from './slices/store/store';
import { useSelector } from 'react-redux';
import Router from 'router/Router';
import Footer from 'ITcomponents/footer/Footer';
import { Answers, Header } from './ITcomponents';
import { Links } from 'ITcomponents/header/links/Links';

export default function App() {
  useEffect(() => {
    if (localStorage.getItem('formsSiteTheme') === null) localStorage.setItem('formsSiteTheme', 'light');
  }, []);
  const siteTheme = useSelector((state: RootState) => state.user.user.siteTheme);
  const headerStatus = useSelector((state: RootState) => state.user.headerState);

  return (
    <main className={`${'site__wrapper'} ${siteTheme === 'dark' && 'dark'}`}>
      <Header child={headerStatus === 'answers'
        ? <Answers /> : headerStatus === 'links'
          ? <Links /> : null} />
      <Router />

      {!window.location.href.includes('auth') &&
        <Footer />
      }
    </main>
  );
}