import { FC, ReactNode } from 'react';
import { AddIcon, DeleteIcon } from 'assets/Assets';
import { Button } from 'ITcomponents/index';
import { IQuestion } from 'intarfaces/form/createForm';
import styles from 'ui/creatingForms/CreatingForms.module.scss';

interface IQuestionMap {
  question: IQuestion,
  qIndex: number,
  handleChangeQuestionDetail1: (e) => void,
  handleChangeQuestionType: (e) => void,
  handleChangeQuestionDetail2: (e) => void,
  handleDeleteQuestion: () => void,
  handleAddChoice: () => void,
  children: ReactNode
}

export const QuestionMap: FC<IQuestionMap> = (props) => {

  return (
    <div key={props.qIndex} className={styles.create__createQuestion__block}>
      <div className={styles.create__questions}>
        <div className={styles.create__setting}>
          <select
            onChange={props.handleChangeQuestionType}
            className={styles.question__select}
            value={props.question.question_type}>
            <option className={styles.question__choice} value="" disabled hidden>Выберите вариант</option>
            <option className={styles.container__select__elem__text} value="TEXT">Текст</option>
            <option className={styles.container__select__elem__text} value="ONE_LIST">Один из списка</option>
            <option className={styles.container__select__elem__text} value="MANY_LIST">Несколько из списка</option>
          </select>
          <DeleteIcon className={styles.deleteIcon__question} onClick={props.handleDeleteQuestion} />
        </div>
        <div className={styles.create__question}>
          <p className={styles.create__text}>{props.qIndex + 1}.</p>
          <input
            placeholder="Вопрос"
            type="text"
            className={styles.question__input}
            onChange={props.handleChangeQuestionDetail1}
            value={props.question.question}
            required
          />
        </div>
        {props.question.question_type !== 'TEXT' && (
          <ul>
            {props.children}
          </ul>
        )}

        <div className={styles.create__end}>
          <div className={styles.create__end__btns}>
            {props.question.question_type !== 'TEXT' && (
              <Button type="button" className="blue__button" onClick={props.handleAddChoice}>
                <AddIcon /> Добавить ответ
              </Button>
            )}
          </div>
          <label className={styles.create__end__checkbox}>
            <p className={styles.create__required}>Обязательно</p>
            <div className={styles.toggleSwitch}>
              <input
                type="checkbox"
                checked={props.question.required}
                onChange={props.handleChangeQuestionDetail2}
              />
              <span className={styles.slider}></span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};