import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'common/constants';
import { FormI, IAnswerPage, IFormLink, IFormTitleEdit } from 'intarfaces/form/createForm';

export const formsApi = createApi({
  reducerPath: 'api',
  tagTypes: ['Forms'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('access');
      if (token) {
        headers.set('Authorization', `JWT ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getRecentForms: builder.query({
      query: userId => `/api/v1/forms/recent`,
      providesTags: (result) => result
        ? [
          ...result.map(({ id }) => ({ type: 'Forms', id })),
          { type: 'Forms', id: 'LIST' },
        ] : [{ type: 'Forms', id: 'LIST' }],
    }),
    getMyForms: builder.query<FormI[], void>({
      query: () => '/api/v1/forms/users',
      providesTags: (result) => result
        ? [
          ...result.map(({ id }) => ({ type: 'Forms', id } as const)),
          { type: 'Forms', id: 'LIST' },
        ] : [{ type: 'Forms', id: 'LIST' }],
    }),
    getAllForms: builder.query<FormI[], void>({
      query: () => '/api/v1/admin/forms',
      providesTags: (result) => result
        ? [
          ...result.map(({ id }) => ({ type: 'Forms', id } as const)),
          { type: 'Forms', id: 'LIST' },
        ] : [{ type: 'Forms', id: 'LIST' }],
    }),
    getFormById: builder.query<FormI, string>({
      query: id => `/api/v1/forms/${id}`,
    }),
    getFormAnalysis: builder.query<IAnswerPage, string>({
      query: id => `/api/v1/analysis/correct/${id}`,
    }),
    exportFormResponsesToXLS: builder.query<Blob, any>({
      query: id => ({
        url: `/api/v1/forms/${id}/responses/export_to_xls`,
        method: 'GET',
        responseHandler: response => response.blob(),
      }),
    }),
    createForm: builder.mutation({
      query: data => ({
        url: '/api/v1/forms/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Forms', id: 'LIST' }],
    }),
    deleteForm: builder.mutation({
      query: id => ({
        url: `api/v1/forms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Forms', id: 'LIST' }],
    }),
    copyFormLink: builder.query<IFormLink, string>({
      query: id => `api/v1/forms/form/${id}`,
    }),
    editFormTitle: builder.mutation<IFormTitleEdit, IFormTitleEdit>({
      query: data => ({
        url: `api/v1/forms/${data.id}`,
        method: 'PATCH',
        body: { title: data.newTitle },
      }),
      invalidatesTags: [{ type: 'Forms', id: 'LIST' }],
    }),
    submitForm: builder.mutation({
      query: ({ id, answers }) => ({
        url: `/api/v1/forms/${id}/submit`,
        method: 'POST',
        body: { answers },
      }),
    }),
  }),
});

export const {
  useCreateFormMutation,
  useGetRecentFormsQuery,
  useGetMyFormsQuery,
  useGetAllFormsQuery,
  useGetFormByIdQuery,
  useGetFormAnalysisQuery,
  useExportFormResponsesToXLSQuery,
  useDeleteFormMutation,
  useCopyFormLinkQuery,
  useEditFormTitleMutation,
  useSubmitFormMutation,
} = formsApi;