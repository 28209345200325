import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ArrowPersonIcon,
  AvatarIcon,
  LogoutIcon,
  ThemeChangingSun,
  ThemeChangingMoon,
} from '../../../assets/Assets';
import styles from './profile.module.scss';
import axios from 'axios';
import { BASE_URL } from 'common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { changeSiteTheme, setUser } from 'slices/user/userSlice';
import { RootState } from 'slices/store/store';

export const ProfileModal = ({ email, name, surname }: { email: string, name: string, surname: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const siteTheme = useSelector((state: RootState) => state.user.user.siteTheme);

  const removeToken = () => {
    axios.post(`${BASE_URL}/api/v1/users/logout`, {
        refresh: localStorage.getItem('refresh'),
      },
      {
        headers: {
          'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
      },
    )
      .then(() => {
        dispatch(setUser({
          user_id: 0,
          name: '',
          surname: '',
          email: '',
          siteTheme: localStorage.getItem('formsSiteTheme'),
          token: null,
          is_staff: false
        }));
        localStorage.removeItem('refresh');
        localStorage.removeItem('access');
        window.location.reload();
        navigate('/auth');
      });
  };

  return (
    <div className={styles.profile__modal}>
      <div onClick={() => setIsOpen(!isOpen)}
           className={styles.profile__icons}>
        <AvatarIcon className={styles.profile__person__img} />
        <ArrowPersonIcon
          className={`${styles.profile__arrow} ${isOpen && styles.active}`} />
      </div>
      {isOpen && (
        <div className={styles.profile__modal__menu}>
          <p className={styles.profile__modal__username}>Здравствуйте, {name} {surname}!</p>
          <p className={styles.profile__modal__email}>{email}</p>
          <div className={styles.profile__modal__change__theme}>
            <button onClick={() => dispatch(changeSiteTheme('dark'))}
                    className={`${styles.change__theme__button} ${siteTheme === 'dark' && styles.theme__active}`}>
              <ThemeChangingMoon className={styles.theme__change__img} />
              Тёмный
            </button>
            <button onClick={() => dispatch(changeSiteTheme('light'))}
                    className={`${styles.change__theme__button} ${siteTheme === 'light' && styles.theme__active}`}>
              <ThemeChangingSun className={styles.theme__change__img} />
              Светлый
            </button>
          </div>
          <Link
            to='/'
            className={styles.profile__modal__exit}
            onClick={removeToken}
          >
            <LogoutIcon className={styles.profile__exit__img} />
            <p className={styles.profile__exit__text}>
              Выйти из аккаунта
            </p>
          </Link>
        </div>
      )}
    </div>
  );
};
