import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeHeaderStatus } from 'slices/user/userSlice';
import styles from '../notFoundPage/notFoundPage.module.scss';

export const ClosedPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeHeaderStatus(''));
  }, []);
  return (
    <>
      <div className={styles.error__container}>
        <div className={styles.error__errorBlock}>
          <h1 className={styles.error__errorTitle}>Страница не доступна</h1>
          <p className={styles.error__errorText}>Форма закрыта владельцем</p>
          <Link to='/' className={styles.error__errorLink}>Вернуться на главную
            страницу</Link>
        </div>
      </div>
    </>
  );
};