import React, { useEffect, useState } from 'react';
import styles from './settings.module.scss';
import { useDispatch } from 'react-redux';
import { changeHeaderStatus } from 'slices/user/userSlice';

const Settings: React.FC = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeHeaderStatus('answers'));
  }, []);

  const [isFormSubmissionLimited, setIsFormSubmissionLimited] = useState(false);

  const [confirmationMessage, setConfirmationMessage] = useState('Ваш ответ записан.');

  const handleFormSubmissionLimitChange = () => {
    setIsFormSubmissionLimited(!isFormSubmissionLimited);
  };

  const handleConfirmationMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationMessage(event.target.value);
  };

  return (
    <div className="container">
      <div className={styles.sett}>
        <h1 className={styles.sett__title}>Настройки</h1>
        <hr className={styles.sett__hr} />
        <div className={styles.sett__container}>
          <label className={styles.sett__container__drop__label}>
            <p className={styles.sett__container__drop__desc}>
              Отправлять форму не более одного раза
            </p>
            <div className={styles.sett__container__drop__checkbox}>
              <input
                type="checkbox"
                checked={isFormSubmissionLimited}
                onChange={handleFormSubmissionLimitChange}
              />
              <span className={styles.sett__container__drop__slider}></span>
            </div>
          </label>
          <div className={styles.sett__container__drop__input}>
            <input
              className={styles.sett__container__drop__input__text}
              type="text"
              value={confirmationMessage}
              onChange={handleConfirmationMessageChange}
            />
            <p className={styles.sett__container__drop__input__change}>Изменить</p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Settings;