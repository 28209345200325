import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "intarfaces/user/user";

const initialState = {
  user: {
    user_id: 0,
    name: '',
    surname: '',
    email: '',
    token: localStorage.getItem('access'),
    siteTheme: localStorage.getItem('formsSiteTheme'),
    is_staff: false,
  },
  headerState: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload
    },
    setToken(state, action: PayloadAction<string>) {
      state.user.token = action.payload
    },
    changeSiteTheme(state, action: PayloadAction<string>) {
      state.user.siteTheme = action.payload
      localStorage.setItem('formsSiteTheme', action.payload)
    },
    changeHeaderStatus(state, action: PayloadAction<string>) {
      state.headerState = action.payload
    }
  }
})

export const {setUser, setToken, changeSiteTheme, changeHeaderStatus} = userSlice.actions

export default userSlice.reducer