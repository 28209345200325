import { IChoiseStatistic } from "intarfaces/form/createForm";
import Chart from "react-apexcharts";

const Gistogram = ({data} : {data: IChoiseStatistic[] }) => {
  
  const answersQuantityList: number[] = []
  const answersNamesList: string[] = []

  for(let i = 0; i < data.length; i++) {
    answersQuantityList.push(data[i].answers_count)
    answersNamesList.push(data[i].name_choice)
  }

  return (
    <Chart 
      type="bar" 
      height={300}
      series={[
        {
          name: "Ответы",
          data: answersQuantityList,
        },
      ]}
      options={{
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#283A97", "#647ae7", "#52a8a0","#2a9431", "#aacc00", "#ff8f00","#c2281c", "#690a45", "#dd80af"],
        plotOptions: {
          bar: {
            columnWidth: "20%",
            borderRadius: 2,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "13px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: answersNamesList,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#616161",
              fontSize: "0.8rem",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 5,
          padding: {
            top: 5,
            right: 20,
          },
        },
        tooltip: {
          theme: "dark",
        },
      }} 
    />
  );
}

export default Gistogram