import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormI } from 'intarfaces/form/createForm';

interface ListFormsState {
  forms: FormI[];
  filter: string;
}

const initialState: ListFormsState = {
  forms: [],
  filter: '',
};

const listFormsSlice = createSlice({
  name: 'listForms',
  initialState,
  reducers: {
    setForms: (state, action: PayloadAction<FormI[]>) => {
      state.forms = action.payload;
    },
    setFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    addForm: (state, action: PayloadAction<FormI>) => {
      state.forms.push(action.payload);
    },
    deleteForm: (state, action: PayloadAction<string>) => {
      state.forms = state.forms.filter(form => form.id !== action.payload);
    },
  },
});

export const { setForms, setFilter, addForm, deleteForm } = listFormsSlice.actions;

export default listFormsSlice.reducer;