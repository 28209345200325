import { Routes, Route, Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import CompletingForm from 'ui/completingForm/CompletingForm';
import CreatingForms from 'ui/creatingForms/CreatingForms';
import ViewAnswer from 'ui/viewAnswer/ViewAnswer';
import PreviewForm  from "ui/PreviewForm/PreviewForm";
import Register from 'ui/auth/register/Register';
import MainPage from 'ui/mainPage/Home';
import Login from 'ui/auth/login/Login';
import GetAllUsers from 'ui/adminPanel/allUsers/GetAllUsers';
import Settings from 'ui/settings/Settings';
import { NotFoundPage } from 'ui/notFoundPage/NotFoundPage';
import { ErrorPage } from 'ui/errorPage/ErrorPage';
import GetListMyForms from 'ui/getListForms/GetListMyForms';
import GetListForms from 'ui/adminPanel/allForms/GetListForms';
import Auth from 'ui/auth/Auth';
import { useDispatch } from 'react-redux';
import { setUser } from 'slices/user/userSlice';

const PrivateRoute = ({ routes }: { routes: JSX.Element }) => {
  return localStorage.getItem('access') !== null ? routes : <ErrorPage />;
};

const PublicRoute = ({ routes }: { routes: JSX.Element }) => {
  return localStorage.getItem('access') === null ? routes : <Navigate to="/" />;
};

const FormAfterLoginRouter = ({ routes }: { routes: JSX.Element }) => {
  const location = useLocation();
  return localStorage.getItem('access') === null ?
    <Navigate to={{
          pathname: '/auth',
          search: `?next=${encodeURIComponent(location.pathname)}`
        }}
      />
    : routes
};

function Router() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="completing/form/:id" element={<FormAfterLoginRouter routes={<CompletingForm />} />}/>
      <Route path="/" element={<PrivateRoute routes={<Outlet />} />}>
        <Route path="creating-form" element={<CreatingForms />} />
        <Route path="answers/view/:id" element={<ViewAnswer />} />
        <Route path="all_forms" element={<GetListMyForms />} />
        <Route path="settings" element={<Settings />} />
        <Route path="preview/:id" element={<PreviewForm />} />
        <Route path="users/admin" element={<GetAllUsers />} />
        <Route path="forms/admin" element={<GetListForms />} />
      </Route>
      <Route path="/" element={<PublicRoute routes={<Outlet />} />}>
        <Route path="auth" element={<Auth />} />
      </Route>
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default Router;

