import { FC, useState } from 'react';
import Login from 'ui/auth/login/Login';
import Register from 'ui/auth/register/Register';

const Auth: FC = () => {
  const [page, setPage] = useState<string | null>("login");


  return (
    <div>
      {page === "register" ? (
        <Register setPage={setPage} />
      ) : page === "login" ? (
        <Login setPage={setPage} />
      ) : null}
    </div>
  );
};

export default Auth;