import { LogoHeaderIcon, LogoIcon } from '../../assets/Assets';
import { Link } from 'react-router-dom';
import styles from './logoBlock.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'slices/store/store';

export const LogoBlock = () => {
  const siteTheme = useSelector((state: RootState) => state.user.user.siteTheme)
  
  return (
    <Link to='/' className={styles.main__titleBlock}>
      {siteTheme === 'dark' ? (
        <LogoHeaderIcon className={styles.main__icon} />
      ) : (
        <LogoIcon className={styles.main__icon} />
      )}
      <h1 className={styles.main__title}>Alabuga Forms</h1>
    </Link>
  )
}
