import { FormQuestionsI } from 'intarfaces/form/createForm';
import styles from './FormVariableAnswer.module.scss';
import { FC, useState } from 'react';
import { addAnswer } from 'slices/forms/FormSlice';
import { useDispatch } from 'react-redux';

export const FormVariableAnswer: FC<FormQuestionsI> = ({ question, choice }) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  return (
    <label className={styles.form__answer}>
      <input
        className={styles[`answer__input__${question.question_type}`]}
        type={question.question_type === 'MANY_LIST' ? 'checkbox' : 'radio'}
        value={choice}
        onClick={() => {
          setIsChecked(!isChecked);
          dispatch(addAnswer({ question, answerValue: choice, isChecked: !isChecked }));
        }}
        name={String(question.id)}
      />
      <p className={styles.answer__title}>
        {choice}
      </p>
    </label>
  );
};