import styles from './FormHeader.module.scss';
import { IFormHeader } from 'intarfaces/form/createForm';

const FormHeader = (data: IFormHeader) => {
  const { title, description, type } = data;
  return (
    <div className={styles.form__header__wrapper}>
      {type === 'answers' && (
        <>
          <h3 className={styles.form__note}>
            Нельзя редактировать ответы пользователей
          </h3>
          <hr className={styles.form__top__hr} />
        </>
      )}
      <h2 className={styles.form__title}>{title}</h2>
      <hr className={styles.form__bottom__hr} />
      <p className={styles.form__description}>
        {description}
      </p>
      {type === 'form' && (
        <>
          <hr className={styles.form__bottom__hr} />
          <h3 className={styles.form__mandatory}>* - это обязательный вопрос</h3>
        </>
      )}
    </div>
  );
};

export default FormHeader;