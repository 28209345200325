import { Link, useLocation } from 'react-router-dom';
import styles from './answers.module.scss';
import { FC } from 'react';

export const Answers: FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const getClassName = (path) => {
    return currentPath === path
      ? `${styles.nav__container__link} ${styles.nav__container__link__active}`
      : styles.nav__container__link;
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.nav__wrapper}>
        <div className={styles.nav__container}>
          <Link to="/creating-form" className={getClassName('/creating-form')}>
            Форма
          </Link>
          {!window.location.href.includes('creating') && !window.location.href.includes('settings') &&(
            <Link to="/answers/view/" className={getClassName('/answers/view')}>
            Ответы
          </Link>
          )}
          {/*<Link to="/settings" className={getClassName('/settings')}>*/}
          {/*  Настройки*/}
          {/*</Link>*/}
        </div>
      </div>
    </nav>
  );
};