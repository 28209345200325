import { Link } from 'react-router-dom';
import styles from './loginOrReg.module.scss'

export const LoginOrRegPath = () => {
  return (
    <div className={styles.nav__auth__container}>
      <Link to='/auth' className={styles.nav__link}>
        Вход | Регистрация
      </Link>
    </div>
  );
};