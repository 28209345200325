import { FC, ReactNode, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LogoHeaderIcon } from '../../../assets/Assets';
import { LoginOrRegPath } from '../loginOrReg/LoginOrRegPath';
import { ProfileModal } from '../profile/ProfileModal';
import styles from './header.module.scss';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'slices/user/userSlice';
import { RootState } from 'slices/store/store';
import { BASE_URL } from 'common/constants';
import { Button } from 'ITcomponents/buttons/blueButton/Button';

interface HeaderI {
  child?: ReactNode;
}

export const Header: FC<HeaderI> = ({ child }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('access');
  const userData = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (token) {
      axios.get(`${BASE_URL}/api/v1/users/user`,
        {
          headers: {
            'Authorization': `JWT ${token}`,
          },
        },
      )
        .then(response => {
          dispatch(setUser({
            name: response.data.user_data.name,
            surname: response.data.user_data.surname,
            email: response.data.user_data.email,
            user_id: response.data.user_data.id,
            token: token,
            siteTheme: localStorage.getItem('formsSiteTheme'),
            is_staff: response.data.user_data.is_staff,
          }));
        })
        .catch(error => {
          console.log('Возникла ошибка', error);
          localStorage.removeItem('access');
          navigate('/auth');
        });
    }
  }, []);


  return (
    <header className={styles.header}>
      <div className='header__container'>
        <div className={styles.header__content__wrapper}>
          <Link to={token ? '/all_forms' : '/'} className={styles.header__logo}>
            <LogoHeaderIcon className={styles.nav__img} />
            <h1 className={styles.header__title}>Alabuga Forms</h1>
          </Link>
          {child}
          <div className={styles.header__user__container}>
            {
              token ? (
                <>
                  {userData.is_staff && (
                    <Button type='button' className='header__link' onClick={() => navigate('/forms/admin')}>
                      Админ-панель
                    </Button>
                  )}
                  <ProfileModal email={userData.email} name={userData.name} surname={userData.surname}/>
                </>
              ) : (
                !window.location.href.includes('auth') && <LoginOrRegPath />
              )
            }
          </div>
        </div>
      </div>
    </header>
  );
};
