import styles from './AnswersAnalys.module.scss';
import PercentageProgressLine from 'ITcomponents/percentageProgressLine/PercentageProgressLine';
import Gistogram from 'ITcomponents/gistogram/Gistogram';
import Diagram from 'ITcomponents/diagram/Diagram';
import { IAnswerPage, IChoiseStatistic, IFormStatistics } from 'intarfaces/form/createForm';
import { QuestionIcon } from 'assets/Assets';
import React from 'react';

const AnswersAnalys = ({ answersData, type }: { answersData: IAnswerPage, type: string }) => {
  
  const printAnswersPercentages = (choices: IChoiseStatistic[]) =>
    choices.map((choice, index) => {
      return (
        <div key={index} className={styles.question__variable}>
          <div className={styles.question__variable__title}>
            <p className={styles.question__variable__name}>
              {choice.name_choice}
            </p>
            <PercentageProgressLine progress={choice.procent} />
          </div>
          <div className={styles.question__variable__percentage__wrapper}>
            <p className={styles.question__variable__answers__quantity}>
              {choice.answers_count}
            </p>
            <p className={styles.question__variable__percentage}>
              {Math.round(choice.procent * 10) / 10}%
            </p>
          </div>
        </div>
      );
    });

  const printStatistics = answersData.statistics.questions.map((element, index) => {
    return (
      <div key={index} className={styles.question__statistics}>
        <div className={styles.question__icon__wrapper}>
          <QuestionIcon className={styles.form__question__icon} />
          <h4 className={styles.question__statistics__name}>
            {element.question}
          </h4>
        </div>
        <p className={styles.question__statistics__answers}>
          {element.answers_count} ответов
        </p>
        {element.choices !== null && (
          <div className={styles.question__statistics__variables}>
            {printAnswersPercentages(element.choices)}
          </div>
        )}
      </div>
    );
  });

  const printGistograms = answersData.statistics.questions.map((element, index) => {
    if (element.choices?.length !== 0 && element.choices !== null) {
      return (
        <div key={index} className={styles.question__gistogram__wrapper}>
          <div className={styles.question__icon__wrapper}>
            <QuestionIcon className={styles.form__question__icon} />
            <h4 className={styles.question__gistogram__title}>
              {element.question}
            </h4>
          </div>
          <Gistogram data={element.choices} />
        </div>
      );
    } else return null;
  });

  const printDiagrams = answersData.statistics.questions.map((element, index) => {
    if (element.choices?.length !== 0 && element.choices !== null) {
      return (
        <div key={index} className={styles.question__gistogram__wrapper}>
          <div className={styles.question__icon__wrapper}>
            <QuestionIcon className={styles.form__question__icon} />
          <h4 className={styles.question__gistogram__title}>
            {element.question}
          </h4>
          </div>
          <Diagram data={element.choices} />
        </div>
      );
    } else return null;
  });


  const printTape = answersData.tape.map((answer, index) => {
    return (
      <div key={index} className={styles.user__answer__analys}>
        <div className={styles.user__answer__info}>
          <p className={styles.user__answer__date}>
            {answer.create_date}
          </p>
          <p className={styles.answer__sender}>
            {answer.sender_name}, {answer.sender_email}
          </p>
        </div>
        {answer.answers.map((answer, answerIndex) => {
          return (
            <div key={answerIndex} className={styles.form__question}>
              <p className={styles.form__question__title}>
                <QuestionIcon className={styles.form__question__icon} />
                {answer.question_name}
              </p>
              <div className={styles.form__question__answers__list}>
                {answer.answers.map((element, index) => {
                  return (
                    <p key={index} className={styles.form__question__answer}>
                      {element}
                    </p>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  });


  const printAnalys = () => {
    switch (type) {
      case 'percentage':
        return (
          <div className={styles.answers__analys__statistics}>
            {printStatistics}
          </div>
        );
      case 'gistogram':
        return (
          <div className={styles.answers__analys__gistograms}>
            {printGistograms}
          </div>
        );
      case 'diagram':
        return (
          <div className={styles.answers__analys__gistograms}>
            {printDiagrams}
          </div>
        );
      case 'tape':
        return (
          <div className={styles.answers__analys__tape}>
            {printTape}
          </div>
        );
    }
  };

  return (
    <div className={styles.answers__analys}>
      {printAnalys()}
    </div>
  );
};

export default AnswersAnalys;