import { FC } from 'react';
import { Form } from 'ITcomponents/form/Form';
import styles from 'ui/getListForms/getListMyForms.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'slices/store/store';
import { FormI } from 'intarfaces/form/createForm';

interface GetMyFormsProps {
  MyForms: FormI[];
}

export const GetAllMyForms: FC<GetMyFormsProps> = ({ MyForms }) => {
  const filter = useSelector((state: RootState) => state.listForms.filter);

  const filteredForms = MyForms.filter(form => form.title.toLowerCase().includes(filter.toLowerCase()));

  return (
    <>
      {filteredForms.length ? (
        <div className={styles.forms__list}>
          {filteredForms.map((form) => (
            <Form key={form.id} title={form.title} id={form.id} created_at={form.created_at} creator={form.creator} />
          ))}
        </div>
      ) : (
        <p className={styles.forms__text}>{filter ? `Форма: "${filter}" не найдена` : 'У вас пока что нет форм'}</p>
      )}
    </>
  );
};