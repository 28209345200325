import React from 'react';
import { IChoiseStatistic } from 'intarfaces/form/createForm';
import Chart from 'react-apexcharts';
import styles from './diagram.module.scss';

const Diagram = ({ data }: { data: IChoiseStatistic[] }) => {
  const answersQuantityList: number[] = [];
  const answersNamesList: string[] = [];

  for (let i = 0; i < data.length; i++) {
    answersQuantityList.push(data[i].answers_count);
    answersNamesList.push(data[i].name_choice);
  }

  return (
    <div className={styles.diagram}>
      <Chart
        type='pie'
        width='100%'
        height='100%'
        series={answersQuantityList}
        options={{
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#283A97', '#647ae7', '#52a8a0', '#2a9431', '#aacc00', '#ff8f00', '#c2281c', '#690a45', '#dd80af'],
          labels: answersNamesList,
        }}
      />
    </div>
  );
};

export default Diagram;