import React, { FC, useEffect, useState } from 'react';
import { Download } from 'assets/Assets';
import { useParams } from 'react-router';
import { useExportFormResponsesToXLSQuery, useGetFormAnalysisQuery } from 'slices/forms/formsApi';
import styles from './viewAnswer.module.scss';
import FormHeader from 'ITcomponents/formHeader/FormHeader';
import AnswersAnalys from 'ITcomponents/answersAnalys/AnswersAnalys';
import { useDispatch } from 'react-redux';
import { changeHeaderStatus } from 'slices/user/userSlice';
import { Button } from 'ITcomponents/index';
import { ErrorPage } from 'ui/errorPage/ErrorPage';
import { IAnswerPage } from 'intarfaces/form/createForm';
import LoadPage from 'ui/loadPage/Load';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from 'common/constants';

const analyseOptionsList = [
  {
    value: 'percentage',
    name: 'Процентаж',
  },
  {
    value: 'diagram',
    name: 'Диаграмма',
  },
  {
    value: 'gistogram',
    name: 'Гистограмма',
  },
];

const ViewAnswer: FC = () => {
  const [currentAnalyse, setCurrentAnalyse] = useState<'tape' | 'table' | 'percentage' | 'diagram' | 'gistogram'>('percentage');
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data: formAnalys = {} as IAnswerPage, isLoading, error } = useGetFormAnalysisQuery(String(id));
  const navigate = useNavigate();
  const token = localStorage.getItem('access');
  const [formCloseStatus, setIsClosed] = useState(false);

  useEffect(() => {
    dispatch(changeHeaderStatus(''));
    window.document.title = 'Аналитика формы';
  }, [dispatch]);

  useEffect(() => {
    if (formAnalys && formAnalys.statistics) {
      setIsClosed(formAnalys.statistics.is_closed);
    }
  }, [formAnalys]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as 'tape' | 'table' | 'percentage' | 'diagram' | 'gistogram';
    setCurrentAnalyse(value);
  };

  const printAnalysOptions = analyseOptionsList.map((option, index) => {
    return <option className={styles.answers__type__option} key={index} value={option.value}>{option.name}</option>;
  });

  const { data: blob } = useExportFormResponsesToXLSQuery(id, { skip: !id });

  const downloadXls = () => {
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'Ответы.xls';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const ClosedForm = () => {
    axios.get(`${BASE_URL}/api/v1/forms/closed/${id}`, {
      headers: {
        'Authorization': `JWT ${token}`,
      }
    }).then(() => setIsClosed(true));
  };

  const OpenForm = () => {
    axios.get(`${BASE_URL}/api/v1/forms/open/${id}`, {
      headers: {
        'Authorization': `JWT ${token}`,
      }
    }).then(() => setIsClosed(false));
  };

  if (isLoading) {
    return (
      <LoadPage></LoadPage>
    );
  }

  return (
    <div className={styles.view__answers}>
      {error ? (
        <ErrorPage />
      ) : (
        <>
          <div className='container'>
            <div className={styles.answers__header__wrapper}>
              <div className={styles.answers__header}>
                <h2 className={styles.answers__header__title}>
                  Просмотр ответов
                </h2>
                <h2 className={styles.answers__header__quantity}>
                  Ответы: {formAnalys.statistics ? formAnalys.statistics.total_users : 0}
                </h2>
              </div>
              <hr />
              <div className={styles.answers__tools__wrapper}>

                <div className={styles.answers__tools}>
                  <select className={styles.answers__view__type}
                          onChange={(event) => handleChange(event)}>
                    {printAnalysOptions}
                  </select>
                  <button className={styles.answers__tab__button}
                          onClick={() => setCurrentAnalyse('tape')}>Лента
                  </button>

                </div>

                <div className={styles.manage__tools__wrapper}>
                  <Button type="button" className="blue__button"
                          onClick={downloadXls}>
                    <Download className={styles.answers__download__img} />
                    <p className={styles.answers__download__text}>Скачать
                      ответы</p>
                  </Button>
                  <Button type="button" className="blue__button"
                          onClick={() => navigate(`/preview/${id}`)}>
                    <p className={styles.answers__download__text}>Предпросмотр формы</p>
                  </Button>
                  {
                    formAnalys.statistics && formCloseStatus == false ?
                      <Button type="button" className="blue__button"
                              onClick={ClosedForm}>
                        <p className={styles.answers__download__text}>Закрыть форму</p>
                      </Button> :
                      <Button type="button" className="blue__button"
                              onClick={OpenForm}>
                        <p className={styles.answers__download__text}>Открыть форму</p>
                      </Button>
                  }
                </div>


              </div>
            </div>
            {formAnalys.statistics &&
              <FormHeader title={formAnalys.statistics.title} description={formAnalys.statistics.description}
                          type={'answers'} />
            }
            {formAnalys.statistics && formAnalys.statistics.questions.length > 0 &&
              <AnswersAnalys answersData={formAnalys} type={currentAnalyse} />
            }
          </div>
        </>
      )}
    </div>
  );
};

export default ViewAnswer;