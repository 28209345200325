import React, { FC, useEffect, useState } from 'react';
import Search from 'ITcomponents/header/search/Search';
import { Person } from 'assets/Assets';
import styles from './getAllUsers.module.scss';
import axios from 'axios';
import { BASE_URL } from 'common/constants';
import { Button } from 'ITcomponents/buttons/blueButton/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeHeaderStatus } from 'slices/user/userSlice';
import LoadPage from 'ui/loadPage/Load';
import { ErrorPage } from 'ui/errorPage/ErrorPage';
import { useGetAllFormsQuery } from 'slices/forms/formsApi';

const GetAllUsers: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setErrorMessage] = useState('');
  const token = localStorage.getItem("access");
  const is_staff = localStorage.getItem("is_staff");

  interface User {
    id: number;
    name: string;
    surname: string;
    email: string;
    is_active: boolean;
    is_staff: boolean;
  }

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get<User[]>(`${BASE_URL}/api/v1/admin/users`, {
          headers: {
            'Authorization': `JWT ${token}`,
          },
        });
        setUsers(response.data);
      } catch (err) {
        console.error('Error fetching users:', err);
        setErrorMessage("The request failed");
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, [token]);

  const updateUserStatus = (user_id: number, is_active?: boolean, is_staff?: boolean) => {
    if (is_staff === false || is_staff === true){
      setUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === user_id ? { ...user, undefined, is_staff} : user
      )
    );
    } else {
      setUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === user_id ? { ...user, is_active: is_active ?? user.is_active} : user
      )
    );
    }
  };

  const blockuser = async (user_id: number) => {
    try {
      await axios.post(`${BASE_URL}/api/v1/admin/${user_id}/block`, {}, {
        headers: {
          Authorization: `JWT ${token}`
        }
      });
      updateUserStatus(user_id, false);
      console.log('Пользователь заблокирован');
    } catch (error) {
      console.error('Ошибка при блокировке пользователя:', error);
    }
  };

  const unblockuser = async (user_id: number) => {
    try {
      await axios.post(`${BASE_URL}/api/v1/admin/${user_id}/unblock`, {}, {
        headers: {
          Authorization: `JWT ${token}`
        }
      });
      updateUserStatus(user_id, true);
      console.log('Пользователь разблокирован');
    } catch (error) {
      console.error('Ошибка при разблокировке пользователя:', error);
    }
  };


  const unblockadmin = async (user_id: number) => {
    try {
      await axios.post(`${BASE_URL}/api/v1/admin/${user_id}/unblock-permissions`, {}, {
        headers: {
          Authorization: `JWT ${token}`
        }
      });
      updateUserStatus(user_id, undefined,true);
      console.log('Админ разблокирован');
    } catch (error) {
      console.error('Ошибка при разблокировке админа:', error);
    }
  };

  const blockadmin= async (user_id: number) => {
    try {
      await axios.post(`${BASE_URL}/api/v1/admin/${user_id}/block-permissions`, {}, {
        headers: {
          Authorization: `JWT ${token}`
        }
      });
      updateUserStatus(user_id, undefined,false);
      console.log('Админ разблокирован');
    } catch (error) {
      console.error('Ошибка при разблокировке админа:', error);
    }
  };

  useEffect(() => {
    dispatch(changeHeaderStatus('search'));
  }, [dispatch]);

  const handleClick = () => {
    navigate('/forms/admin', { replace: true });
  };

  if (error) {
    return <ErrorPage />;
  }

  return (
    <div className={styles.admin}>
      <div className="container">

          <div className={styles.admin__headerBlock__link}>
            <p className={styles.admin__text}>Админ-панель</p>
            <p className={styles.admin__text}>Все пользователи</p>
            <Button className="blue__button" onClick={handleClick}>
              Перейти ко всем формам
            </Button>
          </div>

        <hr className={styles.hr} />
        <Search />
        <div className={styles.admin__filter}>
          <p className={styles.admin__filter__text}>Все</p>
          <p className={styles.admin__filter__text}>Заблокированные</p>
          <p className={styles.admin__filter__text}>Админы</p>
        </div>
        <div className={styles.admin__list}>
          <div className={styles.admin__list__header}>
            <div className={styles.admin__list__container__wrap}>
              <p className={styles.admin__list__container__text}>Аватар</p>
              <p className={styles.admin__list__container__name}>Имя</p>
            </div>
            <div className={styles.admin__list__container__wrap}>
              <p className={styles.admin__list__container__block}>Блокировка</p>
              <p className={styles.admin__list__container__permission}>Права</p>
            </div>
          </div>
          <div className={styles.admin__list__scroll}>
            {users.map((user, index) => (
              <>
                <hr className={styles.admin__list__info__hr} />
                <div className={styles.admin__list__container}>
                  <div className={styles.admin__list__container__wrap}>
                    <Person className={styles.admin__list__container__img} />
                    <div className={styles.admin__list__container__username}>
                      <p
                        className={styles.admin__list__container__name__text}>{user.name} {user.surname}</p>
                    </div>
                    <div className={styles.admin__list__container__username}>
                      <p
                        className={styles.admin__list__container__name__email}>{user.email}</p>
                    </div>
                    <div className={styles.admin__list__container__username}>
                      {is_staff ? (
                        <p
                          className={styles.admin__list__container__permission__text}>Админ</p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={styles.admin__list__container__username}>
                      {!user.is_active ? (
                        <p
                          className={styles.admin__list__container__text__block}>Заблокирован</p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={styles.admin__list__container__username}>
                      {user.is_staff ? (
                        <p
                          className={styles.admin__list__container__permission__text}>Админ</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className={styles.admin__list__container__wrap}>
                    {!user.is_active ? (
                      <p
                        className={styles.admin__list__container__text__unblock}
                        onClick={() => unblockuser(user.id)}>Разблокировать</p>
                    ) : (
                      <p className={styles.admin__list__container__text__block}
                         onClick={() => blockuser(user.id)}>Заблокировать</p>
                    )}

                    {!user.is_staff ? (
                      <p className={styles.admin__list__container__permission__text}
                         onClick={() => unblockadmin(user.id)}>Сделать админом</p>
                    ) : (
                      <p className={styles.admin__list__container__text__block}
                         onClick={() => blockadmin(user.id)}>Лишить прав</p>
                      )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetAllUsers;