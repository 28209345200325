import {BrowserRouter} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {store} from "slices/store/store";
import App from './App'
import './assets/styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,
);
