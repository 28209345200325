import { FC } from 'react';
import { CrossIcon } from 'assets/Assets';
import styles from 'ui/creatingForms/CreatingForms.module.scss';

interface IAnswersMap {
  cIndex: number,
  qIndex: number,
  choice: string,
  handleChangeChoice: (e) => void,
  handleDeleteChoice: () => void,

}

export const AnswersMap: FC<IAnswersMap> = (props) => {

  return (
    <div className={styles.create__answers} key={props.cIndex}>
      <p className={styles.create__counter}>{props.cIndex + 1}</p>
      <input
        type="text"
        value={props.choice}
        required
        className={styles.choice__choiceInput}
        placeholder="Вариант ответа"
        onChange={props.handleChangeChoice}
      />
      <CrossIcon className={styles.create__img}
                 onClick={props.handleDeleteChoice}
      />
    </div>
  );
};