import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'common/constants';
import { RootState } from 'slices/store/store';
import { changeHeaderStatus, setUser } from 'slices/user/userSlice';
import { Button } from 'ITcomponents/buttons/blueButton/Button';
import { LogoBlock } from 'ITcomponents/logoBlock/LogoBlock';
import { ImgComponent } from 'ITcomponents/imgClomponent/Img';
import password from 'assets/img/password.svg';
import email from 'assets/img/mail.svg';
import closeEye from 'assets/img/closeEye.svg';
import openEye from 'assets/img/openEye.svg';
import openEyeWhite from 'assets/img/openEyeWhite.svg';
import closeEyeWhite from 'assets/img/closeEyeWhite.svg';
import emailWhite from 'assets/img/emailWhite.png';
import passwordWhite from 'assets/img/passwordWhite.svg';
import styles from './login.module.scss';

interface AuthProps {
  setPage: Dispatch<SetStateAction<string | null>>;
}

const Login: FC<AuthProps> = (props) => {
  const siteTheme = useSelector((state: RootState) => state.user.user.siteTheme);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginForm = useRef<HTMLFormElement>(null);
  const [searchParams] = useSearchParams();
  const nextUrl = searchParams.get('next') || '/';
  const [errorFields, setErrorFields] = useState<number[]>([]);
  const userData = useSelector((state: RootState) => state.user.user);


  useEffect(() => {
    document.title = 'Sign in';
    dispatch(changeHeaderStatus(''));
  }, [dispatch]);


  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
      setErrorFields([]);
    }, 2500);
  };

  const clearErrorInput = (index: number) => {
    setErrorFields(prevErrorFields => prevErrorFields.filter(errorIndex => errorIndex !== index));
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    const errors: number[] = [];

    if (loginForm.current) {
      const formData = new FormData(loginForm.current);
      const email = formData.get('email') as string;
      const password = formData.get('password') as string;

      if (!email || !password) {
        if (!email) {
          errors.push(0);
        }
        if (!password) {
          errors.push(1);
        }
        setErrorFields(errors);
        setErrorMessage('Заполните все поля');
        clearErrorMessage();
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        errors.push(0);
        setErrorFields(errors);
        setErrorMessage('Введите корректный электронный адрес');
        return;
      }

      axios.post(`${BASE_URL}/api/v1/users/login`, formData)
        .then(response => {
          dispatch(setUser({
            user_id: response.data.user_id,
            name: response.data.name,
            surname: response.data.surname,
            email: response.data.email,
            token: response.data.access,
            is_staff: response.data.is_staff,
            siteTheme: localStorage.getItem('formsSiteTheme'),
          }));
          localStorage.setItem('access', response.data.access);
          localStorage.setItem('refresh', response.data.refresh);
          setErrorMessage('');
          navigate('/all_forms');
          startTimer();
          navigate(nextUrl);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 502) {
              setErrorMessage('Ведутся работы на сервере');
            } else if (error.response.data) {
              setErrorMessage('Неверный логин или пароль');
              let errors = [0, 1];
              setErrorFields(errors);
            }
          } else {
            setErrorMessage('Произошла ошибка при входе');
          }
          clearErrorMessage();
        });
    }
  };

  const startTimer = () => {
    const interval = 900000;
    setInterval(() => {
      requestForUpdatedToken();
    }, interval);
  };

  const requestForUpdatedToken = () => {
    let access = localStorage.getItem('access');

    axios.post(`${BASE_URL}/api/v1/users/update/token`, {
      refresh: localStorage.getItem('refresh'),
    }, {
      headers: {
        'Authorization': `JWT ${access}`,
      },
    })
      .then(response => {
        localStorage.setItem('access', response.data.access);
        dispatch(setUser({
          ...userData,
            token: response.data.access,
          }));
      })
      .catch(error => {
        console.error('Ошибка при отправке запроса', error.message);
        if (error.message === 'Token is invalid or expired') {
          window.location.reload();
          localStorage.clear();
          navigate('/auth');
        } else {
          window.location.reload();
          localStorage.clear();
        }
      });
  };

  const inputFields = [
    {
      src: siteTheme === 'dark' ? emailWhite : email,
      label: 'Электронный адрес',
      type: 'email',
      name: 'email',
      placeholder: 'AlabugaPolytech@alabuga.ru',
    },
    {
      src: siteTheme === 'dark' ? passwordWhite : password,
      label: 'Пароль',
      type: 'password',
      name: 'password',
      placeholder: 'Введите пароль',
    },
  ];

  function returnEyeStatus() {
    return siteTheme === 'dark' ?
      isOpenPassword ? openEyeWhite : closeEyeWhite
      : isOpenPassword ? openEye : closeEye;
  }

  const printInputs = inputFields.map((field, index) => {
      const passwordStatus = returnEyeStatus();
      const inputClass = errorFields.includes(index) ? `${styles.error__block}` : styles.input__block;
      return (
        <label key={index} className={styles.input__wrapper}>
          <p className={styles.input__title}>
            {field.label}
          </p>
          <div className={inputClass} onClick={() => clearErrorInput(index)}>
            <ImgComponent src={field.src} className={styles.input__icon} />
            {field.placeholder.includes('пароль') ? (
              <>
                <input
                  className={styles.login__input}
                  required
                  {...{ ...field, type: isOpenPassword ? 'text' : 'password' }}
                />
                <ImgComponent onClick={() => setIsOpenPassword(!isOpenPassword)} src={passwordStatus}
                              className={styles.input__icon} />
              </>
            ) : (
              <input className={styles.login__input} required {...field} />
            )}
          </div>
        </label>
      );
    },
  );

  return (
    <div className={styles.login}>
      <div className='container'>
        <div className={styles.login__content__wrapper}>

          <form ref={loginForm} className={styles.login__form}>
            <div className={styles.logoblock__wrapper}>
              <LogoBlock />
            </div>
            <h2 className={styles.auth__form__title}>
              Авторизация
            </h2>
            {printInputs}

            <Button
              onClick={handleLogin}
              className={'blue__button'}
            >
              Авторизоваться
            </Button>
            <p className={styles.under__text}>
              Нет аккаунта?<p
                onClick={() => props.setPage('register')}
                className={styles.auth__btn__reg_register}
              >Зарегистрируйтесь!
              </p>
            </p>
            {errorMessage &&
              <p className={styles.auth__form__error__message}>{errorMessage}</p>
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;