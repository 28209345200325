import { FC } from 'react';
import { useGetRecentFormsQuery } from 'slices/forms/formsApi';
import { Form } from 'ITcomponents/form/Form';
import styles from 'ui/getListForms/getListMyForms.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'slices/store/store';

export const GetRecentMyForms: FC = () => {
  const userId = useSelector((state: RootState) => state.user.user.user_id);
  const { data: recentForms = [], error } = useGetRecentFormsQuery(
    userId,
  );

  if (error) {
    return <div>Произошла ошибка</div>;
  }

  return (
      <div className={styles.forms__bg}>
        <div className="container">
          <p className={styles.forms__text}>Недавние формы ️</p>
          <hr className={styles.hr} />
          <div className={styles.forms__list}>
            {recentForms.map((form) => (
              <Form key={form.id} title={form.title} id={form.id} created_at={form.created_at} creator={form.creator}/>
            ))}
          </div>
        </div>
      </div>
  );
};