import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorPage } from '../errorPage/ErrorPage';
import { Button } from 'ITcomponents/buttons/blueButton/Button';
import { GetRecentMyForms } from 'ui/getListForms/components/GetRecentMyForms';
import { GetAllMyForms } from 'ui/getListForms/components/GetAllMyForms';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderStatus } from 'slices/user/userSlice';
import { useGetMyFormsQuery } from 'slices/forms/formsApi';
import LoadPage from 'ui/loadPage/Load';
import styles from './getListMyForms.module.scss';
import Search from 'ITcomponents/header/search/Search';
import { RootState } from 'slices/store/store';

const GetListMyForms: FC = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('access');
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.user.user);
  const { data: MyForms = [], isLoading } = useGetMyFormsQuery();

  useEffect(() => {
    dispatch(changeHeaderStatus('search'));
  }, [dispatch]);

  if (isLoading) {
    return <LoadPage />;
  }

  return token ? (
      <div className={styles.forms}>
          {MyForms.length > 0 && <GetRecentMyForms />}
        <div className="container">
          <div className={styles.forms__createBlock__link}>
            <p className={styles.forms__text}>Мои формы</p>
            {
              userData.is_staff ? (
                <Button className="blue__button" onClick={() => navigate('/creating-form')}>Создать форму</Button>
            ) :
                <></>
            }
          </div>
          <hr className={styles.hr} />
          <Search />
          <GetAllMyForms MyForms={MyForms} />
        </div>
      </div>
  ) : (
    <ErrorPage />
  );
};

export default GetListMyForms;
