import { FC } from 'react';

interface ImgI {
  src?: string;
  alt?: string;
  className?: string;
  onClick?: () => void;
  id?: string;
}

export const ImgComponent: FC<ImgI> = (props) => {
  return (
    <img id={props.id} src={props.src} alt={props.alt} className={props.className} onClick={props.onClick} />
  );
};