import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'common/constants';
import axios from 'axios';
import { RootState } from 'slices/store/store';
import { changeHeaderStatus, setUser } from 'slices/user/userSlice';
import { Button } from 'ITcomponents/buttons/blueButton/Button';
import { LogoBlock } from 'ITcomponents/logoBlock/LogoBlock';
import { ImgComponent } from 'ITcomponents/imgClomponent/Img';
import password from 'assets/img/password.svg';
import email from 'assets/img/mail.svg';
import person from 'assets/img/user.svg';
import closeEye from 'assets/img/closeEye.svg';
import openEye from 'assets/img/openEye.svg';
import personWhite from 'assets/img/personWhite.svg';
import openEyeWhite from 'assets/img/openEyeWhite.svg';
import closeEyeWhite from 'assets/img/closeEyeWhite.svg';
import emailWhite from 'assets/img/emailWhite.png';
import passwordWhite from 'assets/img/passwordWhite.svg';
import styles from './register.module.scss';
import { response } from 'express';

interface AuthProps {
  setPage: Dispatch<SetStateAction<string | null>>;
}

const Register: FC<AuthProps> = (props) => {
  const siteTheme = useSelector((state: RootState) => state.user.user.siteTheme);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const registrationForm = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const [errorFields, setErrorFields] = useState<number[]>([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const nextUrl = searchParams.get('next') || '/';

  useEffect(() => {
    document.title = 'Sign up';
    dispatch(changeHeaderStatus(''));
  }, [dispatch]);

  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
      setErrorFields([])
    }, 2500);
  };

  const clearErrorInput = (index: number) => {
    setErrorFields(prevErrorFields => prevErrorFields.filter(errorIndex => errorIndex !== index));
  };

  const handleRegistration = async (e: FormEvent) => {
    e.preventDefault();

    if (registrationForm.current) {
      const registrationData = new FormData(registrationForm.current);
      const name = registrationData.get('name') as string;
      const surname = registrationData.get('surname') as string;
      const email = registrationData.get('email') as string;
      const password = registrationData.get('password') as string;
      const confirmPassword = registrationData.get('confirmPassword') as string;
      const errors: number[] = [];

      if (!name || !surname || !email || !password || !confirmPassword) {
        if (!name) {errors.push(0)}
        if (!surname) {errors.push(1)}
        if (!email) {errors.push(2)}
        if (!password) {errors.push(3)}
        if (!confirmPassword) {errors.push(4)}
        setErrorFields(errors)
        setErrorMessage('Заполните все поля');
        clearErrorMessage();
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        errors.push(1);
        setErrorFields(errors)
        setErrorMessage('Введите корректный электронный адрес');
        clearErrorMessage();
        return;
      }

      if (password !== confirmPassword) {
        errors.push(2, 3);
        setErrorFields(errors)
        setErrorMessage('Пароли не совпадают');
        clearErrorMessage();
        return;
      } else if (password.length < 8 || confirmPassword.length < 8) {
        errors.push(2, 3);
        setErrorFields(errors)
        setErrorMessage('Пароль меньше 8-ми символов');
        clearErrorMessage();
        return;
      } else if (password.match(/[а-яА-Я]/) || confirmPassword.match(/[а-яА-Я]/)) {
        setErrorMessage('Пароль не должен содержать русских символов');
        errors.push(2);
        setErrorFields(errors)
        clearErrorMessage();
        return;
      }

      registrationData.delete('confirmPassword');

      axios.post(`${BASE_URL}/api/v1/users/register`, registrationData)
        .then((response => {
          dispatch(setUser({
            user_id: response.data.user.id,
            name: response.data.user.name,
            surname: response.data.user.surname,
            email: response.data.user.email,
            token: response.data.access,
            is_staff: response.data.user.is_staff,
            siteTheme: localStorage.getItem('formsSiteTheme'),
          }));
           setErrorMessage('');
           navigate('/all_forms');
           localStorage.setItem('access', response.data.access);
           localStorage.setItem('refresh', response.data.refresh);
           navigate(nextUrl);
        }))
        .catch(error => {
          console.log(error.response.data.email)
          if (error.response) {
          } if (error.response.status === 502) {
            setErrorMessage('Ведутся работы на сервере');
          }  else if (error.response.data.email[0] === 'user with this Email already exists.') {
            setErrorMessage('Пользователь с такой почтой уже существует');
          }
          clearErrorMessage();
        });
    }
  };

  const inputFields = [
    {
      src: siteTheme === 'dark' ? personWhite : person,
      label: 'Имя пользователя',
      type: 'text',
      name: 'name',
      placeholder: 'Имя',
    },
    {
      src: siteTheme === 'dark' ? personWhite : person,
      label: 'Фамилия пользователя',
      type: 'text',
      name: 'surname',
      placeholder: 'Фамилия',
    },
    {
      src: siteTheme === 'dark' ? emailWhite : email,
      label: 'Электронный адрес',
      type: 'email',
      name: 'email',
      placeholder: 'AlabugaPolytech@alabuga.ru',
    },
    {
      src: siteTheme === 'dark' ? passwordWhite : password,
      label: 'Пароль',
      type: 'password',
      name: 'password',
      placeholder: 'Придумайте пароль',
    },
    {
      src: siteTheme === 'dark' ? passwordWhite : password,
      label: 'Повторите пароль',
      type: 'password',
      name: 'confirmPassword',
      placeholder: 'Введите пароль ещё раз',
    },
  ];

  function returnEyeStatus() {
    return siteTheme === 'dark' ?
      isOpenPassword ? openEyeWhite : closeEyeWhite
      : isOpenPassword ? openEye : closeEye;
  }

  const printInputs = inputFields.map((field, index) => {
      const passwordStatus = returnEyeStatus();
      const inputClass = errorFields.includes(index) ? `${styles.error__block}` : styles.input__block;
      return (
        <label key={index} className={styles.input__wrapper}>
          <p className={styles.input__title}>
            {field.label}
          </p>
          <div className={inputClass} onClick={() => clearErrorInput(index)}>
            <ImgComponent src={field.src} className={styles.input__icon} />
            {field.placeholder.includes('пароль') ? (
              <>
                <input
                  className={styles.registration__input}
                  required
                  {...{ ...field, type: isOpenPassword ? 'text' : 'password' }}
                />
                <ImgComponent onClick={() => setIsOpenPassword(!isOpenPassword)} src={passwordStatus}
                              className={styles.input__icon} />
              </>
            ) : (
              <input className={styles.registration__input} required {...field} />
            )}
          </div>
        </label>
      );
    },
  );

  return (
    <>
      <div className={styles.registration}>
        <div className="container">
          <div className={styles.registration__content__wrapper}>

            <form ref={registrationForm} className={styles.registration__form}>
              <div className={styles.logoblock__wrapper}>
                <LogoBlock />
              </div>
              <h2 className={styles.auth__form__title}>
                Регистрация
              </h2>
              {printInputs}

              <Button
                onClick={handleRegistration}
                className={'blue__button'}
              >
                Зарегистрироваться
              </Button>

              <p className={styles.under__text}>
                Уже есть аккаунт?
                  <p
                    onClick={() => props.setPage('login')}
                    className={styles.auth__btn__reg_login}
                  >
                    Авторизуйтесь!
                  </p>
              </p>
              {errorMessage &&
                <p className={styles.auth__form__error__message}>{errorMessage}</p>
              }
            </form>
          </div>
        </div>
      </div>

    </>
  );
};

export default Register;