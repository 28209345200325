import { ImgComponent } from '../imgClomponent/Img';
import Image5 from '../../assets/img/image5.jpg';
import styles from './mainComponents.module.scss';

export const PublicationPreview = () => {
  return (
    <div className={styles.about}>
      <div className={styles.about__wrapper}>
        <ImgComponent className={styles.about__img} src={Image5} />
        <div className={styles.about__text__left}>
          <h1 className={styles.about__title}>После создания формы, вы можете опубликовать её и собирать ответы
            от пользователей. "Alabuga Forms" предоставляет удобные инструменты для анализа и управления
            полученными данными.</h1>
        </div>
      </div>
    </div>
  );
};
export default PublicationPreview;