import React, { FC, useEffect, useState, FormEvent, useRef } from 'react';
import { useParams } from 'react-router';
import { ErrorPage } from 'ui/errorPage/ErrorPage';
import { Button } from 'ITcomponents/index';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderStatus } from 'slices/user/userSlice';
import { useGetFormByIdQuery } from 'slices/forms/formsApi';
import { FormI } from 'intarfaces/form/createForm';
import { FormQuestions } from 'ITcomponents/formQuestions/FormQuestions';
import FormHeader from 'ITcomponents/formHeader/FormHeader';
import { RootState } from 'slices/store/store';
import LoadPage from 'ui/loadPage/Load';
import { Modal } from 'ITcomponents/modal/Modal';
import styles from './previewForm.module.scss';

const PreviewForm: FC = () => {
  const token = localStorage.getItem('access');
  const { id } = useParams();
  const dispatch = useDispatch();
  const answers = useSelector((state: RootState) => state.form.answers);
  const { data = {} as FormI, isLoading, error } = useGetFormByIdQuery(String(id));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingMessage, setSubmittingMessage] = useState('');
  const questionRefs = useRef<Array<HTMLDivElement | null>>([]);
  const messageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch(changeHeaderStatus(''));
    document.title = 'Прохождение формы';
  }, [dispatch]);

  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 2500);
  };

  const clearSubmittingMessage = () => {
    setTimeout(() => {
      setSubmittingMessage('');
    }, 2500);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!validateRequiredQuestions()) {
      setErrorMessage('Пожалуйста, ответьте на все обязательные вопросы.');
      clearErrorMessage();
      const firstUnansweredQuestion = data.questions?.find(question => question.required && !answers.some(answer => answer.question_id === question.id));
      if (firstUnansweredQuestion) {
        const index = data.questions?.indexOf(firstUnansweredQuestion);
        if (index !== undefined && index !== -1) {
          questionRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
      return;
    }

    setIsSubmitting(true);
    setSubmittingMessage('Ваш ответ записан');
    clearSubmittingMessage();
    messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const validateRequiredQuestions = () => {
    if (!data.questions) return true;
    return data.questions.every(question => {
      if (!question.required) return true;
      if (question.question_type === 'MANY_LIST') {
        return answers.some(answer => answer.question_id === question.id);
      }
      return answers.some(answer => answer.question_id === question.id && typeof answer.answer === 'string' && answer.answer.trim() !== '');
    });
  };

  const onRetry = () => {
    window.location.reload();
  };

  const printQuestions = data?.questions?.map((question, index) => (
    <div ref={el => questionRefs.current[index] = el} key={question.id}>
      <FormQuestions index={index + 1} question={question} />
    </div>
  ));

  if (isLoading) {
    return (
      <LoadPage></LoadPage>
    );
  }

  return (
    <>
      {token || error ? (
        <form className={styles.completing__form} onSubmit={onSubmit}>
          <>
            <FormHeader type='form' title={data.title} description={data.description as string} />
            {printQuestions}
            <Button type='submit' className='blue__button'>Сохранить ответ</Button>
          </>
          <div className={styles.completing__form__message__wrap} ref={messageRef}>
            {errorMessage &&
              <p className={styles.completing__form__message__error}>{errorMessage}</p>
            }
            {isSubmitting &&
              <p className={styles.completing__form__message__submitting}>{submittingMessage}</p>
            }
          </div>
        </form>
      ) : (
        <ErrorPage />
      )}
      <Modal
        type='generic'
        props={{
          isOpen: isModalOpen,
          title: modalMessage,
          onClose: () => setIsModalOpen(false),
          onRetry: onRetry,
          onGoToAllForms: () => window.location.href = '/all_forms',
        }}
      />
    </>
  );
};

export default PreviewForm;