import { ImgComponent } from '../imgClomponent/Img';
import Image3 from '../../assets/img/image3.jpg';
import styles from './mainComponents.module.scss';

export const CreatePreview = () => {
  return (
    <div className={styles.about}>
      <div className={styles.about__wrapper}>
        <div className={styles.about__text}>
          <h1 className={styles.about__title}>"Alabuga Forms" позволяют легко создавать различные типы форм,
            такие как опросы, анкеты, регистрационные формы и многое другое.</h1>
        </div>
        <ImgComponent src={Image3} className={styles.about__img} />
      </div>
    </div>
  );
};
