import { useNavigate } from 'react-router-dom';
import { Button } from '../buttons/blueButton/Button';
import styles from './ready.module.scss';

export const ReadyBlock = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('access')

  return (
    <div className={styles.ready}>
      <p className={styles.ready__text}>Готовы начать?</p>
          <Button onClick={token ? () => navigate('/all_forms') : () => navigate('/auth')}
                  className="blue__button">
            Перейти в формы
          </Button>
    </div>
  );
};
