import React from 'react';
import { MailIcon, PhoneIcon, TelegramIcon } from '../../assets/Assets';
import { Link } from 'react-router-dom';
import styles from './footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footer__bgc}>
      <div className={styles.footer__wrapper}>
        <h1 className={styles.footer__header}>Контакты для связи</h1>
        <div className={styles.footer}>
          <Link to="tel:+78555759024" className={styles.footer__contact}>
            <PhoneIcon />
            <p className={styles.footer__text}>+78555759024</p>
          </Link>
          <Link to="hr.support@alabuga.ru" className={styles.footer__contact}>
            <MailIcon />
            <p className={styles.footer__text}>hr@alabuga.ru</p>
          </Link>
          <Link to="https://t.me/hr_alabuga_bot" className={styles.footer__contact}>
            <TelegramIcon />
            <p className={styles.footer__text}>Чат в Telegram</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;