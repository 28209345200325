import { FC, useState, useRef, useEffect } from 'react';
import {
  AvatarIcon,
  EllipsisBlackIcon,
  EllipsisWhiteIcon,
} from '../../assets/Assets';
import { FormI } from 'intarfaces/form/createForm';
import { ImgComponent } from '../imgClomponent/Img';
import removeI from '../../assets/img/rename.svg';
import copyI from '../../assets/img/copy.svg';
import deleteI from '../../assets/img/deleteTwo.svg';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'ITcomponents/modal/Modal';
import { useDeleteFormMutation } from 'slices/forms/formsApi';
import styles from './form.module.scss';
import { changeHeaderStatus, setUser } from 'slices/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from 'common/constants';
import { RootState } from 'slices/store/store';

export const Form: FC<FormI> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(props.title);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [deleteForm] = useDeleteFormMutation();
  const userData = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    navigate(`/answers/view/${props.id}`);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteForm(props.id);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Failed to delete form:', error);
    }
  };

  const decodeDateValidation = (dataString) => {
    const date = new Date(dataString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}г`;
  }

  const decodedDate = decodeDateValidation(props.created_at);

  return (
    <div className={`${styles.form} ${isOpen ? styles.form__open : ''}`} onClick={handleClick}>
      { userData.is_staff ? (
        <div className={styles.form__response1}>
        <AvatarIcon className={styles.profile__person__img} />
          <p className={styles.form__select__user}>{props.creator?.['surname'] ?? 'Username not available'}</p>
        </div>
      ) : (
        <></>
      )}
      <div className={styles.form__titleBlock}>
        <h3 className={styles.form__title}>{props.title}</h3>
      </div>
      <div className={styles.form__response}>
        <p className={styles.form__select__date}>{decodedDate}</p>
        <div ref={wrapperRef}>
          <span className={styles.form__select__text} onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}>
            {
              userData.siteTheme === "dark" ? (
                <EllipsisWhiteIcon className={styles.form__ellip} />
              ) : (
                <EllipsisBlackIcon className={styles.form__ellip} />
              )
            }
            {isOpen && (
              <div className={styles.form__select__elem}>
                <div key={0} className={styles.form__select__elem__container}
                     onClick={() => setIsEditModalOpen(true)}>
                  <ImgComponent className={styles.form__select__elem__pic} src={removeI} />
                  <p className={styles.form__select__elem__text}>Переименовать</p>
                </div>
                <div key={1} className={styles.form__select__elem__container}
                     onClick={() => setIsLinkModalOpen(true)}>
                  <ImgComponent className={styles.form__select__elem__pic} src={copyI} />
                  <p className={styles.form__select__elem__text}>Скопировать ссылку</p>
                </div>
                <div key={2} className={styles.form__select__elem__container}
                     onClick={() => setIsModalOpen(true)}>
                  <ImgComponent className={styles.form__select__elem__pic} src={deleteI} />
                  <p className={styles.form__select__elem__text}>Удалить</p>
                </div>
              </div>
            )}
          </span>
        </div>
      </div>

      <Modal
        type="delete"
        props={{
          isOpen: isModalOpen,
          formTitle: props.title,
          onConfirm: handleDeleteConfirm,
          onCancel: () => setIsModalOpen(false),
        }}
      />

      <Modal
        type="copyLink"
        props={{
          isOpen: isLinkModalOpen,
          id: String(props.id),
          onCancelConfirm: () => setIsLinkModalOpen(false),
        }}
      />

      <Modal
        type="editTitle"
        props={{
          isOpen: isEditModalOpen,
          id: String(props.id),
          currentTitle: props.title,
          newTitle,
          setNewTitle,
          onConfirm: () => setIsEditModalOpen(false),
          onCancel: () => setIsEditModalOpen(false),
          onClose: () => setIsEditModalOpen(false),
        }}
      />
    </div>
  );
};