import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import formReducer from 'slices/forms/FormSlice';
import listFormsReducer from 'slices/forms/listFormsSlice';
import userReducer from 'slices/user/userSlice'
import { formsApi } from 'slices/forms/formsApi';

const rootReducer = combineReducers({
  form: formReducer,
  listForms: listFormsReducer,
  user: userReducer,
  [formsApi.reducerPath]: formsApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(formsApi.middleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

