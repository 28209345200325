import React from 'react';
import { LogoHeaderIcon } from 'assets/Assets';
import styles from './load.module.scss';

interface LoadPageProps {
  className?: string;
}

const LoadPage: React.FC<LoadPageProps> = ({ className }) => {
  return (
    <div className={styles[`${className || 'load__bgc'}`]}>
      <LogoHeaderIcon className={styles.load} />
    </div>
  );
};

export default LoadPage;