import { FC } from 'react';
import { ButtonI } from 'intarfaces/button/button';
import styles from './Button.module.scss'


export const Button: FC<ButtonI> = (props) => {

  return (
    <button className={styles[`${props.className}`]}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      {props.children}
    </button>
  );
};

