import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import QRCode from 'react-qr-code';
import { CrossIcon } from 'assets/Assets';
import { Button } from 'ITcomponents/buttons/blueButton/Button';
import LoadPage from 'ui/loadPage/Load';
import { useCopyFormLinkQuery, useEditFormTitleMutation } from 'slices/forms/formsApi';
import styles from './modal.module.scss';
import { FRONT_URL } from 'common/constants';

interface ModalProps {
  type: 'copyLink' | 'delete' | 'editTitle' | 'generic';
  props: any;
}

export const Modal: FC<ModalProps> = ({ type, props }) => {
  const [formLink, setFormLink] = useState('');
  const { data, error, isLoading } = useCopyFormLinkQuery(props.id, { skip: type !== 'copyLink' });
  const [editFormTitle] = useEditFormTitleMutation();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (data) setFormLink(data.id);
  }, [data]);

  const handleCopyLink = () => {
    const fullUrl = `${FRONT_URL}/completing/form/${formLink}`;
    const tempInput = document.createElement('input');
    tempInput.value = fullUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 5000);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setNewTitle(e.target.value);
  };

  const handleSubmit = async () => {
    await editFormTitle({
      id: props.id,
      newTitle: props.newTitle,
    });
    props.onClose();
  };

  if (!props.isOpen) return null;

  return (
    <div className={styles.modalBackdrop} onClick={(e) => e.stopPropagation()}>
      <div className={styles.modal}>
        <button className={styles.cancelCross} onClick={props.onCancel || props.onClose || props.onCancelConfirm}>
          <CrossIcon />
        </button>
        {type === 'copyLink' && (
          <>
            {isLoading ? (
              <div className={styles.modal__load__wrap}>
                <LoadPage className={styles.modal__load__bgc} />
              </div>
            ) : (
              <div className={styles.modal__container}>
                <h2 className={styles.modal__title_text}>QR-код</h2>
                <QRCode
                  className={styles.modal__qr}
                  value={`${FRONT_URL}/completing/form/${formLink}`}
                  onClick={handleCopyLink}
                />
                <h2 className={styles.modal__title_text}>Ссылка на форму</h2>
                <hr className={styles.modal__line_light} />
                <input
                  className={styles.modal__text}
                  type="text"
                  value={`${FRONT_URL}/completing/form/${formLink}`}
                  readOnly
                />
              </div>
            )}
            <div className={styles.modal__buttonContainer}>
              <Button type="button" className="blue__button" onClick={props.onCancelConfirm}>
                Отмена
              </Button>
              <Button type="button" className="blue__button"  onClick={handleCopyLink}>
                Скопировать
              </Button>
            </div>
          </>
        )}
        {type === 'delete' && (
          <>
            <h2 className={styles.modal__title_text}>Удалить форму</h2>
            <p className={styles.modal__text}>Форма "{props.formTitle}" будет удален навсегда. Вы уверены?</p>
            <div className={styles.modal__buttonContainer}>
              <Button type="button" className="blue__button" onClick={props.onCancel}>
                Отмена
              </Button>
              <Button type="submit" className="blue__button" onClick={props.onConfirm}>
                Удалить
              </Button>
            </div>
          </>
        )}
        {type === 'editTitle' && (
          <>
            <h2 className={styles.modal__title_text}>Переименовать</h2>
            <hr className={styles.modal__line_light} />
            <p className={styles.modal__text_edit}>Введите новое название формы:</p>
            <input
              type="text"
              value={props.newTitle}
              onChange={handleInputChange}
              className={styles.modal__input}
              placeholder="Название формы"
            />
            <div className={styles.modal__buttonContainer}>
              <Button type="button" className='blue__button' onClick={props.onClose}>
                Отмена
              </Button>
              <Button className="blue__button" onClick={handleSubmit}>
                Сохранить
              </Button>
            </div>
          </>
        )}
        {type === 'generic' && (
          <>
            <h1
              className={`${styles.modal__success__desc} ${props.title === 'Ваш ответ записан' ? styles.modal__success__desc__good : styles.modal__success__desc__error}`}>{props.title}</h1>
            <div className={styles.btnBlock}>
              <Button className="blue__button" onClick={props.onRetry}>Пройти ещё раз</Button>
              <Button className="blue__button" onClick={props.onGoToAllForms}>Перейти на все формы</Button>
            </div>
          </>
        )}
      </div>
      {isCopied && <p className={styles.modal__copiedText}>Ссылка скопирована!</p>}
      {error && <p className={styles.modal__loadingText}>Ошибка!</p>}
    </div>
  );
};