import styles from './PercentageProgressLine.module.scss'

const PercentageProgressLine = ({progress} : {progress: number}) => {
    return (
        <div className={styles.question__percentage__line}>
            <div className={styles.question__percentage__progress} style={{width: `${progress}%`}}/>
        </div>
    )
}

export default PercentageProgressLine