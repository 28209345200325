import { FC } from 'react';
import { Form } from 'ITcomponents/form/Form';
import styles from 'ui/getListForms/getListMyForms.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'slices/store/store';
import { FormI } from 'intarfaces/form/createForm';

interface GetAllFormsProps {
  allForms: FormI[];
}

export const GetAllForms: FC<GetAllFormsProps> = ({ allForms }) => {
  const filter = useSelector((state: RootState) => state.listForms.filter);

  const filteredForms = allForms.filter(form => form.title.toLowerCase().includes(filter.toLowerCase()));

  return (
    <>
      {filteredForms.length ? (
        <div className={styles.forms__list}>
          {filteredForms.map((form) => (
            <Form key={form.id} title={form.title} id={form.id} creator={form.creator} created_at={form.created_at}/>
          ))}
        </div>
      ) : (
        <p className={styles.forms__text}>{filter ? `Форма: "${filter}" не найдена` : 'Нету ни одной формы'}</p>
      )}
    </>
  );
};