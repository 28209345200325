import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorPage } from 'ui/errorPage/ErrorPage';
import { Button } from 'ITcomponents/buttons/blueButton/Button';
import { GetAllForms } from './components/GetAllForms';
import { useDispatch } from 'react-redux';
import { changeHeaderStatus } from 'slices/user/userSlice';
import { useGetAllFormsQuery } from 'slices/forms/formsApi';
import LoadPage from 'ui/loadPage/Load';
import styles from './getListForms.module.scss'
import Search from 'ITcomponents/header/search/Search';
import { TRUE } from 'sass';
import replace from '@rollup/plugin-replace';

const GetListForms: FC = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('access');
  const dispatch = useDispatch();

  const { data: allForms = [], isLoading } = useGetAllFormsQuery();

    const handleClick = () => {
    navigate('/users/admin', { replace: true });
  };


  useEffect(() => {
    dispatch(changeHeaderStatus('search'));
  }, [dispatch]);

  if (isLoading) {
    return <LoadPage />;
  }

  return token ? (
      <div className={styles.forms}>
          {/*{allForms.length > 0 && <GetRecentMyForms />}*/}
        <div className="container">
          <div className={styles.forms__headerBlock__link}>
            <p className={styles.forms__text}>Админ-панель</p>
            <p className={styles.forms__text}>Все формы</p>
            <Button className="blue__button" onClick={handleClick}>
                Перейти к пользователям
           </Button>
          </div>
          <hr className={styles.hr} />
          <Search />
          <GetAllForms allForms={allForms} />
        </div>
      </div>
  ) : (
    <ErrorPage />
  );
};

export default GetListForms;
