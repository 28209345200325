import { useState } from 'react';
import { SearchIcon } from '../../../assets/Assets';
import styles from './search.module.scss';
import { useDispatch } from 'react-redux';
import { setFilter } from 'slices/forms/listFormsSlice';

const Search = () => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    dispatch(setFilter(e.target.value));
  };

  return (
    <div className={[styles.main__inputBlock, styles.nav__wrapper].join(' ')}>
      <SearchIcon className={styles.main__icon} />
      <input
        className={styles.main__input}
        placeholder='Ваш текст'
        type='text'
        value={value}
        autoComplete='off'
        onChange={handleChange}
      />
    </div>
  );
};

export default Search;