import { Button } from '../../buttons/blueButton/Button';
import styles from './links.module.scss';

export const Links = () => {
  const handleClickHi = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClickReview = () => {
    const targetElement = document.getElementById('targetElementReview');
    if (targetElement) {
      const targetY = targetElement.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({ top: targetY, behavior: 'smooth' });
    }
  };

  const handleClickFunc = () => {
    const targetElement = document.getElementById('targetElementFunc');
    if (targetElement) {
      const targetY = targetElement.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({ top: targetY, behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.nav__link_wrapper}>
      <Button onClick={handleClickHi} className="header__link">Приветствие</Button>
      <Button onClick={handleClickReview} className="header__link">Обзор</Button>
      <Button onClick={handleClickFunc} className="header__link">Функции</Button>
    </div>

  );
};