import { FC, useEffect, useState } from 'react';
import {
  PreviewComponent,
} from 'ITcomponents/mainComponents/PreviewComponent';
import {
  SettingPreview,
} from 'ITcomponents/mainComponents/SettingPreview';
import {
  PublicationPreview,
} from 'ITcomponents/mainComponents/PublicationPreview';
import { ReadyBlock } from 'ITcomponents/readyBlock/ReadyBlock';
import {
  CreatePreview,
} from 'ITcomponents/mainComponents/CreatePreview';
import {
  WelcomeComponent,
} from 'ITcomponents/mainComponents/WelcomeComponent';
import { useDispatch } from 'react-redux';
import { changeHeaderStatus } from 'slices/user/userSlice';
import LoadPage from 'ui/loadPage/Load';
import styles from './home.module.scss';

const Home: FC = () => {
  const dispatch = useDispatch();
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  useEffect(() => {
    document.title = 'Главная';
    dispatch(changeHeaderStatus('links'));
  }, [dispatch]);

  return (
    <div className={styles.body}>
      {isImageLoaded && <LoadPage></LoadPage>}
      <div className={styles.main__container}>
        <div className={styles.bgc}>
          <WelcomeComponent />
          <div className={styles.header}>
            <div className={styles.header__wrapper}>
              <h1 id='targetElementReview' className={styles.header__text}>Обзор
                “Alabuga Forms”</h1>
            </div>
          </div>
          <PreviewComponent />
          <hr className={styles.hr} />
          <div className={styles.header}>
            <div className={styles.header__wrapper}>
              <h1 id='targetElementFunc' className={styles.header__text}>Создание
                форм</h1>
            </div>
          </div>
          <CreatePreview />
          <div className={styles.header}>
            <div className={styles.header__wrapper}>
              <h1 className={styles.header__text}>Настраиваемые вопросы</h1>
            </div>
          </div>
          <PublicationPreview />
          <div className={styles.header}>
            <div className={styles.header__wrapper}>
              <h1 className={styles.header__text}>Публикация и сбор ответов</h1>
            </div>
          </div>
          <SettingPreview setIsImageLoaded={setIsImageLoaded} />
          <hr className={styles.hr} />
        </div>
        <ReadyBlock />
      </div>
    </div>
  );
};

export default Home;