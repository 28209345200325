import { ImgComponent } from '../imgClomponent/Img';
import Image2 from '../../assets/img/image2.jpg';
import styles from './mainComponents.module.scss';

export const PreviewComponent = () => {
  return (
    <div className={styles.about}>
      <div className={styles.about__wrapper}>
        <ImgComponent src={Image2} className={styles.about__img} />
        <div className={styles.about__text__left}>
          <h1 className={styles.about__title}>Наша форма обладает множеством функций, обеспечивающих
            эффективное взаимодействие с пользователями.</h1>
          <p className={styles.about__pre__title}>Отличительные особенности включают интуитивно понятный
            интерфейс, а также возможность просмотра и редактирования информации в режиме реального
            времени.</p>
        </div>
      </div>
    </div>
  );
};
