import { useEffect } from 'react';
import { ImgComponent } from '../imgClomponent/Img';
import Image4 from '../../assets/img/image4.jpg';
import styles from './mainComponents.module.scss';

export const SettingPreview = ({ setIsImageLoaded }) => {
  useEffect(() => {
    const img = new Image();
    img.src = Image4;
    img.onload = () => {
      setIsImageLoaded(false);
    };
  }, [setIsImageLoaded]);

  return (
    <div className={styles.about}>
      <div className={styles.about__wrapper}>
        <div className={styles.about__text}>
          <h1 className={styles.about__title}>Вы можете настраивать вопросы формы в соответствии с вашими
            потребностями. Добавляя текстовые поля, галочки, радиокнопки, выпадающие списки и другие
            элементы.</h1>
        </div>
        <ImgComponent src={Image4} className={styles.about__img} />
      </div>
    </div>
  );
};

export default SettingPreview;