import { Link } from 'react-router-dom';
import { Header } from 'ITcomponents/header/mainHeader/Header';
import styles from './notFoundPage.module.scss';

export const NotFoundPage = () => {
  return (
    <>
      <Header />
      <div className={styles.error__container}>
        <div className={styles.error__errorBlock}>
          <h1 className={styles.error__errorTitle}>Страница не найдена</h1>
          <p className={styles.error__errorText}>Запрашиваемая страница не существует или была удалена.</p>
          <Link to='/' className={styles.error__errorLink}>Вернуться на главную страницу</Link>
        </div>
      </div>
    </>
  );
};