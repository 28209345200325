import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateForm, IFormAnswers, IQuestion } from 'intarfaces/form/createForm';

interface UpdateQuestionDetailPayload {
  questionIndex: number;
  field: string;
  value: string | boolean;
  choiceIndex?: number;
}

const formSlice = createSlice({
  name: 'form',
  initialState: {
    form: {
      title: '',
      description: '',
      questions: [] as IQuestion[],
      creator: '',
      choices: [],
    } as ICreateForm,
    answers: [] as IFormAnswers[],
  },
  reducers: {
    setForm: (state, action: PayloadAction<ICreateForm>) => {
      state.form = action.payload;
    },
    addQuestion: (state, action: PayloadAction<IQuestion>) => {
      state.form.questions.push(action.payload);
    },
    deleteQuestion: (state, action: PayloadAction<number>) => {
      const questionIndex = action.payload;
      state.form.questions = state.form.questions.filter((_, index) => index !== questionIndex);
    },
    updateQuestionDetail: (state, action: PayloadAction<UpdateQuestionDetailPayload>) => {
      const { questionIndex, field, value, choiceIndex } = action.payload;
      if (choiceIndex !== undefined) {
        state.form.questions[questionIndex].choices[choiceIndex] = value as string;
      } else {
        (state.form.questions[questionIndex] as any)[field] = value;
      }
    },
    addChoice: (state, action: PayloadAction<{ questionIndex: number, value: string }>) => {
      const { questionIndex, value } = action.payload;
      state.form.questions[questionIndex].choices.push(value);
    },
    deleteChoice: (state, action: PayloadAction<{ questionIndex: number, choiceIndex: number }>) => {
      const { questionIndex, choiceIndex } = action.payload;
      state.form.questions[questionIndex].choices.splice(choiceIndex, 1);
    },
    addAnswer: (state, action: PayloadAction<{ question: IQuestion, answerValue?: string, isChecked?: boolean }>) => {
      const { question, answerValue, isChecked } = action.payload;
      const questionId = question.id;
      const answerIndex = state.answers.findIndex(answer => answer.question_id === questionId);

      const createAnswer = (answer: string | string[]): IFormAnswers => ({
        question_id: questionId,
        answer: answer,
      });

      switch (question.question_type) {
        case 'MANY_LIST': {
          let currentAnswers = answerIndex !== -1 ? state.answers[answerIndex].answer as string[] : [];
          if (isChecked) {
            currentAnswers = [...currentAnswers, answerValue as string];
          } else {
            currentAnswers = currentAnswers.filter(answer => answer !== answerValue);
          }
          if (answerIndex !== -1) {
            state.answers[answerIndex] = createAnswer(currentAnswers);
          } else {
            state.answers.push(createAnswer(currentAnswers));
          }
          break;
        }
        case 'ONE_LIST':
        case 'TEXT': {
          if (answerIndex !== -1) {
            state.answers[answerIndex] = createAnswer(answerValue as string);
          } else {
            state.answers.push(createAnswer(answerValue as string));
          }
          break;
        }
        default:
          break;
      }
    },
  },
});

export const {
  setForm,
  addQuestion,
  deleteQuestion,
  updateQuestionDetail,
  addChoice,
  deleteChoice,
  addAnswer,
} = formSlice.actions;

export default formSlice.reducer;